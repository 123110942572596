<template>
  <div>
    <div class="row col-12 justify-content-end">
      <a
        href="#"
        class="btn btn-success float-right ml-2"
        v-on:click="fetchData()"
      >
        <i class="fas fa-sync fa-fw"></i
      ></a>
    </div>
    <v-container>
      <div v-if="!isLoadingRoutemaps && dataFetched">
        <template v-if="items.length > 0">
          <template v-for="item in items">
            <div class="row mb-5" v-bind:key="item.id">
              <div class="col-12 t-head">
                <h1>
                  #{{ item.id }} {{ item.product.name }} <br />
                  <span>{{ item.full_info.hallNames }}</span> <br />
                  {{ formatDate(item.full_info.materialDate) }} г.
                </h1>
              </div>

              <div class="col-12">
                <template v-for="(cost, i) in item.full_info.costStandards">
                  <div class="row border-bottom" v-bind:key="i">
                    <div class="col-6 text-strong">
                      {{ cost.type }} {{ cost.name }}
                    </div>
                    <div class="col-2">
                      <span v-for="(b, kk) in cost.batch" v-bind:key="kk">
                        {{ b }}
                      </span>
                    </div>
                    <div class="col-2">{{ cost.expirationDate }}</div>
                    <div class="col-2">{{ cost.allQuantity }}</div>
                  </div>
                </template>
              </div>

              <h4
                class="alert alert-danger row no-gutters"
                v-if="!item.full_info.hallsIsCleaned"
              >
                Залата е отбелязана за почистване
              </h4>

              <div
                class="col-12"
                v-if="item.full_info.deniedControlSentToLabourer"
              >
                <div
                  class="alert alert-danger mb-0"
                  :set="(latestControl = _.last(item.full_info.controls))"
                >
                  Залата е отбелязана за корекциия поради следните причини:
                  <template v-if="!latestControl.package">
                    <br />
                    Външен вид, цвят, цялост на опаковката
                  </template>
                  <template v-if="!latestControl.instructions">
                    <br />
                    Съответствие на продукта с инструкциите за преопаковане
                  </template>
                  <template v-if="!latestControl.batch">
                    <br />
                    Четливост и достоверност на партиден &numero; и срок на
                    годност
                  </template>
                  <template v-if="latestControl.notes">
                    <br />
                    Забележки: "{{ latestControl.notes }}"
                  </template>
                </div>
              </div>

              <div class="col-12" v-if="item.full_info.hallsIsCleaned">
                <div class="row">
                  <div class="col-12" v-if="!item.full_info.isCharging">
                    <span
                      class="btn btn-block btn-danger"
                      @click="signLoad(item)"
                      >Потвърдете, че залата е заредена</span
                    >
                  </div>
                  <div
                    class="col-12"
                    v-else-if="item.full_info.deniedControlSentToLabourer"
                  >
                    <span
                      class="btn btn-block btn-danger"
                      @click="signLoad(item)"
                      >Потвърдете, че сте извършили корекциите</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>

      <h4 v-if="items.length == 0" class="alert alert-info">
        В момента няма маршрутни карти за зареждане.
      </h4>

      <v-progress-linear
        class="mt-5"
        color="#25bdad"
        buffer-value="0"
        stream
        reverse
        :active="isLoadingRoutemaps"
      ></v-progress-linear>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";

import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import {
  GET_ROUTEMAPS_FOR_LOAD,
  GET_ROUTEMAP_INFO,
  SIGN_LOAD,
  FIX_CONTROL
} from "@/store/routemaps.module";

import { mapGetters } from "vuex";

import moment from "moment";

export default {
  name: "ListLoad",

  components: {},

  data() {
    return {
      codes: [],
      dataFetched: false,
      action: "products",
      tableOptions: {
        showSearch: false,
        draggableCols: true,
        selectable: true,
        rowsPerPage: [2, 10, 20, 50],
        maxVisibleButtons: 5
      },
      items: [],
      totalItems: 0,
      totalPages: 0,
      timer: null
    };
  },
  computed: {
    ...mapGetters(["isLoadingRoutemaps"]),
    orderIcon: function() {
      let vm = this;

      return vm.headers.orderType == "asc" ? "▲" : "▼";
    }
  },
  watch: {},
  mounted() {
    let vm = this;

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Продукти" }]);

    vm.fetchData();

    vm.timer = setInterval(() => {
      vm.fetchData();
    }, 60 * 1000);
  },
  methods: {
    formatDate: function(rr) {
      return moment(rr).format("HH:mm DD.MM.YYYY");
    },
    fetchData: function() {
      let vm = this;

      let payload = {
        payload: {
          columns: [
            {
              name: "id",
              search: ""
            },
            {
              name: "product.name",
              search: ""
            },
            {
              name: "quantity",
              search: ""
            },
            {
              name: "materialDate",
              search: ""
            }
          ],
          search: "",
          rows: 10,
          page: 0,
          order: "id",
          orderType: "asc"
        }
      };

      vm.dataFetched = false;

      vm.$store
        .dispatch(GET_ROUTEMAPS_FOR_LOAD, payload)
        .then(data => {
          vm.items = data.data;

          const routeMapInfoPromises = [];

          for (let i in vm.items) {
            routeMapInfoPromises.push(vm.getRoutemapInfo(vm.items[i]));
          }

          Promise.all(routeMapInfoPromises).then(() => {
            vm.dataFetched = true;
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "productsList" });
          }
        });
    },
    getRoutemapInfo: function(routemap) {
      return new Promise(resolve => {
        let vm = this;

        vm.$store.dispatch(GET_ROUTEMAP_INFO, routemap.id).then(data => {
          routemap.full_info = data.data;
          resolve();
        });
      });
    },
    signLoad: function(item) {
      let vm = this;

      if (vm.dataFetched === false) {
        return;
      }

      let action = SIGN_LOAD;
      let payload = {
        payload: {
          routeMapId: item.id,
          code: vm.codes[item.id]
        }
      };

      if (item.full_info.deniedControlSentToLabourer == true) {
        action = FIX_CONTROL;
        let latestControl = _.last(item.full_info.controls);

        payload = {
          payload: {
            control_id: latestControl.id,
            code: vm.codes[item.id]
          }
        };
      }

      vm.$store
        .dispatch(action, payload)
        .then(() => {
          vm.fetchData();
          vm.codes = [];
        })
        .catch(response => {
          if (response.status === 422 && response.data.errors.code) {
            vm.$notification["error"]({
              message: response.data.errors.code[0]
            });
          }
        });
    }
  },

  beforeDestroy: function() {
    this.items = null;
    delete this.items;

    clearInterval(this.timer);
  }
};
</script>

<style scoped>
.btn {
  font-size: 17px;
}
</style>
